<script>
import VueApexCharts from 'vue-apexcharts'

export default {
  name: 'ExerciceDetailsGraphApex',
  components: { apexchart: VueApexCharts },
  props: {
    gadAll: { type: Object, default: () => {} },
    property: { type: Object, default: () => {} }
  },
  computed: {
    propKey () {
      return this.property?.gadKey ?? 'natures'
    },
    naturesTotal () {
      return this.propKey === 'natures'
        ? this.gadAll?.natures?.reduce((prev, curr) => prev + curr.count, 0) + ' questions'
        : ''
    },
    series () {
      return this.gadAll?.[this.propKey]?.map(gad => gad.count) ?? null
    },
    labels () {
      return this.gadAll?.[this.propKey]?.map(gad => gad.titre)
    },
    chartOptions () {
      return {
        chart: {
          type: 'donut'
        },
        legend: {
          position: 'right',
          horizontalAlign: 'center'
        },
        labels: this.labels,
        dataLabels: {
          formatter: function (pourc) {
            return Math.round(pourc) + '%'
          }
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                name: { show: true },
                value: { show: true },
                total: {
                  show: this.naturesTotal !== '',
                  label: 'Total',
                  formatter: (txt) => {
                    return this.naturesTotal
                  }
                }
              }
            }
          }
        },
        noData: {
          text: this.$t('exercices.detail-nodata') + this.property?.btn,
          align: 'center',
          verticalAlign: 'middle',
          offsetX: 0,
          offsetY: 0,
          style: {
            color: 'black',
            fontSize: '12px'
          }
        }
      }
    }
  }
}
</script>

<template lang="pug">
.exercice-details-graph-apex
  .apexchart-container
    apexchart.apexchart(v-if="series" type="donut" :options="chartOptions" :series="series" :height="350")
</template>

<style lang="sass" scoped>
.apexchart-container
  display: flex
  justify-content: center
  align-items: center
  height: 350px
  max-height: 350px
  width: 100%
  max-width: 100%
  margin: auto
  .apexchart
    flex-grow: 1

.exercice-details-graph-apex::v-deep
  .apexcharts-legend
    // 'jc:center' doesnt work when there are a lot of items, idk why...
    // justify-content: center
</style>
