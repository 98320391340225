<script>
export default {
  name: 'LecturesPreview',
  props: {
    activeId: { required: true, type: String },
    slug: { required: false, type: String },
    exercice: { required: false, type: Boolean, default: () => false },
    query: { required: false, type: Object, default: () => {} }
  },
  components: {
  },
  data () {
    return {
      produit: null,
      nuageItem: null,
      total: 0,
      listLectures: null,
      loadingNuage: true
    }
  },
  apollo: {
    elementLectures: {
      query: require('@/graphql/queries/v2/minimalCalendrierCalendrierElements.gql'),
      variables () {
        return {
          page: 1,
          filtre: {
            metas: [
              { data: this.activeId, type: this.exercice ? 'boutiqueItem' : 'extrait' }
            ]
          },
          batch: true,
          identifiant: true
        }
      },
      update (data) {
        this.total = data.calendrierCalendrierElements.total
        this.listLectures = data.calendrierCalendrierElements.resultat
        this.loadingNuage = false
        // this.getMoreLecture(1)
        return data.calendrierCalendrierElements
      },
      watchLoading (isLoading) {
        this.loadingNuage = isLoading
      },
      fetchPolicy: 'network-only'
    }
  },
  watch: {
    activeId (newVal) {
      if (newVal) {
        this.loadingNuage = true
      }
    }
  },
  methods: {
    getLinkForRessource (res) {
      return this.exercice
        ? { name: 'devoirDetail', params: { id: res.identifiant }, query: { identifiant: true } }
        : { name: 'lectures___', query: { document: res.identifiant } }
    }
  }
}
</script>

<template lang='pug'>
  div
    template(v-if='loadingNuage')
      v-list-item.eleve-card__main-content(depressed, flat, v-for='n in 3')
        v-skeleton-loader.w-100(type="list-item-two-line")
    template(v-else-if='listLectures && listLectures.length')
      div(v-for='(list, index) in listLectures')
        v-list-item.eleve-card__main-content.animated.fadeIn(depressed, flat)
          v-list-item-content
            v-list-item-title() {{list.titre}}
          div.actions__wrapper
            v-btn(fab, depressed, color='secondary', small, :to='getLinkForRessource(list)')
              font-awesome-icon(:icon="['far', 'arrow-right']")
        v-divider(v-if='index < listLectures.length - 1')
    div.empty-content__wrapper.pa4(v-else)
      div(v-if='!exercice') {{$t('messages.tuto-partage')}}
      div(v-else) {{$t('messages.tuto-devoir')}}
      v-btn.mt3(rounded, color='primary', :to="{ name: (exercice ? 'devoirEdition___' : 'lectureEdition___') + $i18n.locale, params: { lang: $i18n.locale }, query }") {{ exercice ? $t('action.creer-nouveau-devoir') : $t('action.creer-list') }}
</template>
<style lang='sass'>
</style>
