<script>
import openLink from '@/mixins/openLink.js'

export default {
  name: 'GetClasseContenu',
  mixins: [openLink],
  props: {
    contenu: { required: true, type: Object }
  },
  components: {
  },
  data () {
    return {
      page: 1,
      searchQMotThem: '',
      loadingSearch: false,
      loading: false,
      options: {
        page: 1,
        limit: 25
      },
      headersResults: [
        { text: this.$t('partage.titre'), value: 'name', show: true, filterable: true },
        { text: this.$t('partage.types'), value: '__typename', show: true },
        { text: this.$t('partage.actions'), value: 'actions', align: 'right' }
      ],
      search: null
    }
  },
  created () {
    // this.getAllNode()
  },
  apollo: {
  },
  methods: {
    goToSerie (item) {
      let temp = 'https://www.laclassealamaison.com'
      if (item && item.slug) {
        temp += '/lecon/' + item.slug
      }
      temp += '?dokUANE=' + this.$store.state.User.profile.info.username + '&access=' + this.$store.state.User.profile.info.accessCode
      this.writeTokenInCookiesAndOpenLink(temp)
    },
    goToExo (item, graph) {
      let temp = 'https://www.laclassealamaison.com/lecon/'
      if (item.slug && graph) {
        temp += item.slug + '/graph/' + graph.idtab
      }
      temp += '?dokUANE=' + this.$store.state.User.profile.info.username + '&access=' + this.$store.state.User.profile.info.accessCode
      this.writeTokenInCookiesAndOpenLink(temp)
    }
  }
}
</script>

<template lang="pug">
  .get-detail-contenu.w-100
    template(v-if='!loading')
      .detail-noeud-contenu.relative
        template(v-if='contenu')
          v-expansion-panel.w-100(light)
            v-expansion-panel-header.b(:color='$store.state.Preferences.darkMode ? "light" : "white"', style='padding: 0')
              v-list-item
                v-list-item-content
                  div(style='font-weight:normal;') {{ contenu.name }}
                v-list-item-action
                  v-btn(:color='$store.state.Preferences.darkMode ? "secondary" : "primary"', rounded, x-small, dark, @click.stop='goToSerie(contenu)', outlined)
                    span {{$t('action.consulter')}}
            v-expansion-panel-content(style='padding:0;', :color='$store.state.Preferences.darkMode ? "light darken-2" : "light"', light)
              div(v-if='contenu.children && contenu.children.length')
                div.pa3
                  v-text-field(v-model='search', append-icon='mdi-magnify', :label='$t("action.find-exo")', rounded, outlined, dense, hide-details, background-color='white')
                v-data-table(
                  dense
                  :headers='headersResults',
                  item-key='id',
                  :loading='loadingSearch',
                  :items='contenu.children.filter(e => search ? e.name.includes(search) : e)',
                  :server-items-length='contenu.children.length',
                  items-per-page.sync='10',
                  :options.sync='options',
                  :search='search',
                  :mobile-breakpoint='0',
                  :hide-default-footer='contenu.children.length < 10',
                  :no-data-text='search ? $t("alerte.aucun-result-recherche", { search }) : $t("alerte.aucun-result-afficher")',
                )
                  template(v-slot:item.actions='{ item }')
                    div.flex.items-center.justify-end
                      v-btn.ml2(icon, small, @click='goToSerie(item)', :v-tooltip.bottom="{ content: $t('partage.apercu') , offset:'5px'}")
                        font-awesome-icon(:icon="['fad', 'eye']")
      v-divider()

    template(v-else)
      template(v-for='(n, index) in 2')
        v-skeleton-loader(type='list-item')
        v-divider(v-if='index < 1')
</template>

<style>
</style>
