<script>
import ExerciceCard from '@/components/presentation/exerciceCard.vue'
import ExercicePresentation from '@/components/exercices/exercicePresentation.vue'
import transferBiblioListe from '@/components/dialogs/actions/transferBiblioListe.vue'
import ContentHandleList from '@/components/bibliotheque/biblioHandleList.vue'
import ClasseMaisonPresentation from '@/components/exercices/classeMaisonPresentation.vue'

import { debounce, orderBy, uniqBy } from 'lodash-es'

export default {
  name: 'exercices-index',
  components: {
    ExerciceCard,
    ExercicePresentation,
    ContentHandleList,
    transferBiblioListe,
    ClasseMaisonPresentation
  },
  data () {
    return {
      search: null,
      listView: false,
      selectedCategorie: { title: this.$t('action.tout-voir'), value: '0' },
      searchBib: null,
      searchBibDebounce: null,
      dialogCode: false,
      selectedList: 0,
      loadingNuage: true,
      nuageItems: null,
      totalNuageItems: 0,
      searchNuage: null,
      filterList: null,
      drag: false
    }
  },
  watch: {
    '$store.state.App.mobileTemplate': {
      immediate: true,
      handler (newVal) {
        this.listView = newVal
      }
    }
  },
  computed: {
    showCreateEvent: {
      get () { return this.$store.state.Dialogs.showCreateEvent },
      set (val) { this.$store.commit('Dialogs/setCreateEvent', val) }
    },
    listesBiblio: {
      get () { return this.$store.state.Bibliotheque.listesBiblio },
      set (val) { this.$store.commit('Bibliotheque/setListesBiblio', val) }
    },
    filteredList () {
      const exercicesList = this.filterList && this.$store.state.App.mobileTemplate ? this.filterList.items : this.globalList
      return exercicesList.filter(ex => {
        if (this.$store.state.Exercices.showOnlyFavs) {
          const elId = ex.boutiqueItem.id
          if (this.$store.state.User.favoris.findIndex(f => f.favori?.id === elId) === -1) {
            return false
          }
        }
        return ex.finAt !== null ? this.$dayjs().isBefore(ex.finAt) : true
      })
    },
    globalList () {
      if (this.items) {
        return this.items.filter((el) => {
          if (this.searchBibDebounce && el.titre) {
            if (el.titre.toLowerCase().includes(this.searchBibDebounce.toString().toLowerCase())) {
              return true
            } else {
              return false
            }
          } else {
            return true
          }
        })
      } else {
        return []
      }
    },
    items () {
      if (this.nuageItems && this.totalNuageItems > 0) {
        const order = this.$store.state.Bibliotheque.biblioOrder
        if (order) {
          // console.log('on va faire le orderby')
          return orderBy(
            uniqBy([...this.nuageItems], 'id'),
            function (e) {
              // console.log('ON CHECK LE TYPE', e)
              if (order.includes('date')) {
                return e.created_at || e.createdAt
              } else {
                return e.titre || e.boutiqueItem.titre
              }
            },
            order.includes('Up') ? 'asc' : 'desc'
          )
        } else {
          return orderBy(
            uniqBy([...this.nuageItems], 'id'),
            function (e) {
              return e.created_at || e.createdAt
            },
            'desc'
          )
        }
      } else {
        return []
      }
    },
    nuageItemV1 () {
      return this.$route.query.document === 'classe-a-la-maison' && this.nuageItems?.length === 1
        ? this.nuageItems[0]
        : {}
    }
  },
  apollo: {
    searchNuage: {
      query: require('@/graphql/queries/v2/searchNuageItems.gql'),
      variables () {
        return {
          page: 1,
          q: this.searchBibDebounce,
          filtre: { formats: ['config-boutique-types-de-formats-exercices-en-ligne'] }
          // tri: [this.activeFilter]
        }
      },
      update (data) {
        this.totalNuageItems = data.searchNuageItems.total
        this.nuageItems = data.searchNuageItems.resultat
        this.getMoreNuage(1)
        return data.searchNuageItems
      },
      fetchPolicy: 'network-only',
      loadingKey: 'loading',
      watchLoading (isLoading) {
        this.loadingNuage = isLoading
      }
    }
  },
  methods: {
    async getMoreNuage (page) {
      if (this.totalNuageItems > this.nuageItems.length > 0) {
        const newPage = page + 1
        await this.$apollo.queries.searchNuage.fetchMore({
          variables: { page: newPage },
          updateQuery: (previousResult, { fetchMoreResult }) => {
            const newRes = fetchMoreResult.calendrierCalendrierElements.resultat
            this.nuageItems.push(...newRes)
            this.getMoreNuage(newPage)
          }
        })
      } else {
        this.loadingNuage = false
      }
    },
    resetQuery () {
      let query = Object.assign({}, this.$route.query)
      delete query.document
      this.$router.replace({ query })
      this.selectedList = null
    },
    toggleListView (val) {
      this.listView = val
    },
    updateSearch: debounce(function updateSearch (val) {
      this.searchBibDebounce = val
    }, 200, { trailing: true }),
    onIntersect (entries, observer, isIntersecting) {
      // console.log('INTERSECT', entries)
      // console.log('oec', observer)
      // console.log('ifi', isIntersecting)
    }
  }
}
</script>

<template lang='pug'>
  .bibliotheque-index.split-page
    div.biblio-wrapper
      //- pre {{globalList}}
      content-handle-list(origin='exercices', :searchBib='searchBib', :produits='globalList', @togglelistView='toggleListView', @updateSearch='updateSearch')
      .produits-showcase
        transition(name='custom-classes-transition', enter-active-class='animated faster fadeIn', leave-active-class='animated faster fadeOut', mode='out-in')
          template(v-if='$route.query.document')
            //- pre {{$route.query.document}}
            template(v-if='$route.query.document === "classe-a-la-maison"')
              classe-maison-presentation(:key='$route.query.document' :nuage="nuageItemV1")
            template(v-else)
              exercice-presentation(:classemaison='($route.query.document === "classe-a-la-maison")', :key='$route.query.document', :nuage='$route.query.document && nuageItems && nuageItems.length > 0 && nuageItems.find(e => e.boutiqueItem.slug === $route.query.document)')
          template(v-else)
            div.w-100
              template(v-if='$store.state.Bibliotheque.loadingBiblio || loadingNuage')
                div(v-if='$store.state.App.mobileTemplate')
                  template(v-for='n in 10')
                    v-skeleton-loader.h-100.w-100.mx-auto.loading-card(type='list-item-avatar')
                .produit-cards__wrapper(v-else)
                  template(v-for='n in 10')
                    v-sheet(min-height='325', width='220', rounded, color='transparent')
                      v-skeleton-loader.h-100.w-100.mx-auto.loading-card(type='card', height='325px')
              .produit-cards__wrapper(v-else, :class='{"list-view" : listView}', style='padding-top:0;')
                template(v-if='filteredList && filteredList.length')
                  template(v-if='listView')
                    v-list(rounded, style='padding-top:0; padding-bottom: 100px')
                      v-list-item()
                        exercice-card.animated.fadeIn(:classemaison='true', :produit='{}', :listView='listView')
                      template(v-for='(livre, i) in filteredList')
                        v-list-item(:key='i', style='padding:0;', @click='')
                          exercice-card.animated.fadeIn(:produit='livre.boutiqueItem ? livre.boutiqueItem : livre', :listView='listView', origin='exercices')
                        v-divider(v-if='i < filteredList.length - 1')
                        template(v-else, v-intersect='onIntersect')
                  template(v-else)
                    v-lazy(:options="{ threshold: .5 }", :min-height="listView ? '50' : '325'")
                      exercice-card.animated.fadeIn(:classemaison='true', :produit='{}', :listView='listView')
                    v-lazy(:options="{ threshold: .5 }", :min-height="listView ? '50' : '325'", v-for='(livre, i) in filteredList', :key='i')
                      exercice-card.animated.fadeIn(:produit='livre.boutiqueItem ? livre.boutiqueItem : livre', :listView='listView')
              template(v-if='(!filteredList || filteredList.length === 0) && !$store.state.Bibliotheque.loadingBiblio && !loadingNuage ')
                div.f4.tc.pa4 {{$t('messages.aucun-docu')}} {{filterList ? $t('lecture.dans-section') + filterList.titre + '.' : searchBib ? $t('lecture.correspondant') + searchBib : '.'}}
</template>
<style lang='sass'>
  @import 'src/styles/pages/_bibliotheque'
  .bibliotheque-index
    .v-skeleton-loader__card
      background-color: white
      height: 325px
      display: flex
      flex-direction: column
      .v-skeleton-loader__card-heading
        flex-grow: 1
    .ghost
      opacity: 0.5
      background: lightgrey
    .list-group
      min-height: 20px
    .list-group-item
      cursor: move
      i
        cursor: pointer
</style>
