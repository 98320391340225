<script>
import GroupeDistribution from '@/components/presentation/groupeDistribution.vue'

export default {
  name: 'GroupesDistribution',
  components: { GroupeDistribution },
  props: {
    nuageItemId: { type: String, default: '' }
  },
  data: () => ({
    loading: true,
    salleUsagers: []
  }),
  watch: {
    '$store.state.Dialogs.selectedEleves': {
      deep: true,
      handler (newVal) {
        this.$apollo.queries.salleClasses.refetch()
      }
    }
  },
  apollo: {
    salleClasses: {
      query: require('@/graphql/queries/v2/opti/searchSalleClasses.gql'),
      variables () {
        return {
          page: 1,
          filtre: { nuageItems: this.nuageItemId }
        }
      },
      update (data) {
        this.loading = false
        return data.searchSalleClasses.resultat
      },
      fetchPolicy: 'no-cache'
    }
  }
}
</script>

<template lang="pug">
.groupes-distribution
  v-expansion-panels(v-if="!loading" light accordion)
    groupe-distribution(v-for="classe in salleClasses" :key="classe.id" :classe='classe' :nuageItemId='nuageItemId')
    template(v-if="!salleClasses.length")
      slot(name="no-student-found")
  v-expansion-panels(v-else light accordion)
    .groupe-distribution.w-100
      v-expansion-panel
        v-expansion-panel-header.white--text.b(color='secondary', dark) {{ $t('boite.loading') }}
</template>
