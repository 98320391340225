<script>
import ElevePreview from '@/components/presentation/elevePreview.vue'
export default {
  name: 'GroupeDistribution',
  props: {
    classe: { required: false, type: Object },
    nuageItemId: { required: false, type: String }
  },
  data: () => ({
    page: 1,
    limit: 8
  }),
  watch: {
    '$store.state.Dialogs.selectedEleves': {
      deep: true,
      handler (newVal) {
        this.$apollo.queries.salleClasseSalleUsager.refetch()
      }
    }
  },
  components: {
    ElevePreview
  },
  apollo: {
    salleClasseSalleUsager: {
      query: require('@/graphql/queries/v2/eleveSelection/searchSalleClasseSallesUsagersByCalendrierElement.gql'),
      variables () {
        return {
          page: this.page,
          limit: this.limit,
          filtre: {
            nuageItems: this.nuageItemId,
            salles: this.classe.id
          }
        }
      },
      update (data) {
        console.log('refertch', { data })
        return data.searchSalleClasseSallesUsagers.resultat
      },
      fetchPolicy: 'no-cache'
    }
  },
  computed: {
    pageNumber () {
      return Math.ceil(this.salleClasseSalleUsager?.total / this.limit)
    }
  }
}
</script>

<template lang="pug">
.groupe-distribution.w-100
  v-expansion-panel
    v-expansion-panel-header.white--text.b(color='secondary', dark) {{classe ? classe.titre : $t('messages.autre-eleve') }}
    v-expansion-panel-content(style='padding:0;', :color='$store.state.Preferences.darkMode ? "light" : "white"', light)
      div(v-for='(user, index) in salleClasseSalleUsager' :key='index')
        eleve-preview(:user='user.usager', :attribution='true')
        v-divider(v-if='index < salleClasseSalleUsager.length - 1')
      v-pagination.pagination-wrapper(v-if='salleClasseSalleUsager && salleClasseSalleUsager.total > limit', v-model='page', :total-visible="5", :length='pageNumber', :value='page', circle, color='primary')
</template>

<style lang='sass'>
.groupe-distribution
  &:not(:last-child)
    border-bottom: solid 1px white
  .v-expansion-panel
    .v-expansion-panel-header
      border-bottom: none !important
      .v-expansion-panel-header__icon
        .v-icon
          color: white !important
</style>
