<script>
import ExerciceDetailsGraphApex from '@/components/exercices/exerciceDetailsGraphApex'
import boutiqueSup from '@/mixins/boutiqueSup'

export default {
  name: 'ExerciceDetailsGraph',
  components: { ExerciceDetailsGraphApex },
  mixins: [boutiqueSup],
  props: {
    devoirsContenus: { type: Array, required: true },
    noeuds: { required: false, type: Array, default: () => [] }
  },
  data () {
    return {
      loading: true,
      gad: null,
      selectedPropIndex: 0
    }
  },
  async mounted () {
    const gad = await this.getMultipleGraphArbreData(this.devoirsContenus)
    this.gad = gad
    this.loading = false
  },
  computed: {
    gadAll () {
      if (this.gad === null) { return null }

      const output = { natures: [], matieres: [], niveaux: [] }

      for (const gad of this.gad) {
        // Natures
        for (const nat of gad.nat) {
          const natIndex = output.natures.findIndex(n => n.id === nat.id)
          if (output.natures[natIndex]?.count) {
            output.natures[natIndex].count += nat.count
            output.natures[natIndex].titre = nat.pluriel
          } else {
            const natCopy = JSON.parse(JSON.stringify(nat))
            natCopy.titre = natCopy.count > 1 ? natCopy.pluriel : nat.titre
            output.natures.push(natCopy)
          }
        }

        // Matieres
        for (const m of gad.matieres) {
          const mIndex = output.matieres.findIndex(n => n.id === m.id)
          if (output.matieres[mIndex]?.count) {
            output.matieres[mIndex].count += m.count
          } else {
            const mCopy = JSON.parse(JSON.stringify(m))
            output.matieres.push(mCopy)
          }
        }

        // Niveaux
        for (const n of gad.niveaux) {
          const nIndex = output.niveaux.findIndex(m => m.id === n.id)
          if (output.niveaux[nIndex]?.count) {
            output.niveaux[nIndex].count += n.count
          } else {
            const nCopy = JSON.parse(JSON.stringify(n))
            output.niveaux.push(nCopy)
          }
        }
      }

      for (const noeud of this.noeuds) {
        // Matieres
        for (const m of noeud.contenu.matieres ?? []) {
          const mIndex = output.matieres.findIndex(n => n.id === m.id)
          if (output.matieres[mIndex]?.count) {
            output.matieres[mIndex].count += 1
          } else {
            output.matieres.push({
              id: m.id,
              titre: m.titre,
              count: 1
            })
          }
        }

        // Niveaux
        for (const n of noeud.contenu.niveaux ?? []) {
          const nIndex = output.niveaux.findIndex(m => m.id === n.id)
          if (output.niveaux[nIndex]?.count) {
            output.niveaux[nIndex].count += 1
          } else {
            output.niveaux.push({
              id: n.id,
              titre: n.titre,
              count: 1
            })
          }
        }
      }

      return output
    },
    selectedProperty () {
      return this.properties[this.selectedPropIndex]
    },
    properties () {
      return this.noeuds?.length
        ? [
          { btn: this.$i18n.t('exercices.detail-mat'), gadKey: 'matieres' },
          { btn: this.$i18n.t('exercices.detail-niv'), gadKey: 'niveaux' }
        ]
        : [
          { btn: this.$i18n.t('exercices.detail-comp'), gadKey: 'natures' },
          { btn: this.$i18n.t('exercices.detail-mat'), gadKey: 'matieres' },
          { btn: this.$i18n.t('exercices.detail-niv'), gadKey: 'niveaux' }
        ]
    }
  }
}
</script>

<template lang="pug">
.exercice-details-graph
  h2 {{ $t('exercices.details-about-ex') }}
  exercice-details-graph-apex.my-5(:gadAll="gadAll" :property="selectedProperty")
  h3 {{ $t('exercices.detail-property') }}
  v-btn-toggle(v-model="selectedPropIndex" mandatory)
    v-btn(v-for="prop in properties" :key="prop.gadKey") {{ prop.btn }}
</template>

<style lang="sass" scoped>
.exercice-details-graph
  padding: 16px 12px
  h2
    margin-bottom: 12px
  h3
    margin-bottom: 8px
</style>
