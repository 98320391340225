<script>
import GetClasseContenu from '@/components/bibliotheque/getClasseContenu.vue'
import openLink from '@/mixins/openLink.js'

export default {
  name: 'PresentationCamProduits',
  mixins: [openLink],
  props: {
    nuage: { required: false, type: Object, default: () => null },
    classemaison: { required: false, type: Boolean, default: false }
  },
  components: {
    GetClasseContenu
  },
  data () {
    return {
      infoProduit: null,
      nuageItem: null,
      boutiqueItem: null,
      loading: true,
      panel: 0,
      pageNuage: 1,
      panels: [0],
      expand: false
    }
  },
  apollo: {
  },
  computed: {
    treeviewExercices () {
      return this.$store.state.App.treeviewExercices
    },
    mobileTemplate () {
      return this.$store.state.App.mobileTemplate
    },
    profile () {
      return this.$store.state.User.profile
    },
    createDevoirLink () {
      return this.nuage?.boutiqueItem?.slug
        ? { name: 'devoirEdition___', query: { produit: this.nuage.boutiqueItem.id } }
        : { name: 'devoirEdition___' }
    }
  },
  watch: {
    isDistribuable: {
      immediate: true,
      handler (newVal) {
        if (newVal) {
          this.expand = false
        } else {
          this.expand = true
        }
      }
    },
    '$store.state.App.showSnackbarGlobal' (newVal) {
      if (newVal && this.$store.state.Dialogs.selectedEleves.length > 0) {
        this.giveToChild()
      }
    }
  },
  mounted () {
    this.$store.dispatch('App/fetchRessourcesExercices')
  },
  methods: {
    async giveToChild () {
      const variab = { item: this.nuageItem.id }
      for (let i = 0; i < this.selectedEleves.length; i++) {
        if (this.selectedEleves[i].type === 'eleve') {
          variab.usagers ? variab.usagers.push(this.selectedEleves[i].id) : variab.usagers = [this.selectedEleves[i].id]
        } else if (this.selectedEleves[i].type && this.selectedEleves[i].type === 'classe') {
          variab.salles ? variab.salles.push(this.selectedEleves[i].id) : variab.salles = [this.selectedEleves[i].id]
        }
      }
      await this.$apollo.mutate({
        mutation: require('@/graphql/mutations/v2/distribuerNuageItems.gql'),
        variables: variab
      }).then(({ data }) => {
        if (data.distribuerNuageItems) {
          this.$apollo.queries.nuageItem.refetch()
        }
      })
    },
    addToHisto () {
      this.$store.commit('App/addEntryToHisto', this.produit)
    },
    getFontSize (mot) {
      if (mot.length <= 10) {
        return 2 + 'em'
      } else if (mot.length > 10 && mot.length <= 20) {
        return 2 + 'em'
      } else if (mot.length > 20 && mot.length <= 40) {
        return 2 + 'em'
      } else if (mot.length > 30 && mot.length <= 50) {
        return 2 + 'em'
      } else {
        return 1.75 + 'em'
      }
    },
    async goToContent () {
      const idVar = this.$route.query.id || this.$route.query.document
      let temp = null
      temp = 'https://glossaire.dokoma.com/v2/fr/document/' + idVar
      this.writeTokenInCookiesAndOpenLink(temp)
    }
  }
}
</script>

<template lang='pug'>
  .produit-index.split-page()
    v-sheet.page-header(color='secondary')
      .header-image
        v-img.relative(src='@/assets/images/logos/signature-classe-maison.svg')
      .header__inner-content
        div.flex.justify-between.items-center.w-100.mb3
          v-btn.white--text(:small='$store.state.App.mobileTemplate', :to='{ name: "exercices"}', rounded, color='primary')
            font-awesome-icon.mr2.f4(:icon="['fas', 'arrow-left']")
            span  Tous les exercices
        div.flex-grow-1
        template
          v-avatar.mb1.relative(size='45', color='transparent')
            div.color-fallback()
            v-img.relative(src='@/assets/images/logos/logo-classe-maison.svg')
          .header-title()
            div.secondary-title Exercices
            div.main-title(:style="{ fontSize: getFontSize('Classe à la maison')}") Classe à la maison

    .page-actions
      v-card(v-if='nuage', color='primary', :to='createDevoirLink', v-ripple)
        v-card-title Créer une leçon ou un devoir interactif

    .page__main-content.mt3.animated.fadeIn
      section.fullsize-section
        div.relative.mb3
          v-expand-transition
            v-card.transparent(v-show='expand', height='100%', width='100%', flat)
              div.produit-description Vous trouverez ici tous vos favoris de la Classe à la maison. Tous les éléments disponibles ici pourront être ajoutés à des devoirs que vous pourrez attribuer à vos élèves.
          v-btn(@click='expand = !expand', text, block, small, rounded ,outlined)
            | {{expand ? 'Masquer la description' : 'Afficher la description'}}
            font-awesome-icon.ml2(:icon="['fas', expand ? 'caret-up' : 'caret-down']", style='font-size: 18px')

      section.large-section.mb4
        .section-subtitle__header
          h2.section-subtitle Contenu
          v-btn(small, outlined, rounded, href="https://www.laclassealamaison.com", target='_blank') Ajouter de nouveaux contenus
        v-sheet.section-card(style='padding: 0; overflow: hidden;')
          v-list(v-if='treeviewExercices')
            v-expansion-panels(v-model='panels', light)
              template(v-for='(contenu, index) in treeviewExercices')
                get-classe-contenu(:contenu='contenu')
          div.empty-content__wrapper.pa4(v-else)
            div Vous n'avez aucun favoris dans la Classe à la maison. Ajoutez-en dès maintenant pour les voir dans votre espace.
            v-btn.mt3(rounded, color='primary', @click='showElevesSelection = !showElevesSelection') Mettre en favoris

</template>
<style lang='sass'>
  @import 'src/styles/components/bibliotheque/_produit'
  .section-card.groupes
    .v-expansion-panels
      .v-expansion-panel
        &:not(:last-of-type)
          border-bottom: solid 1px white
      .v-expansion-panel-content
        .v-expansion-panel-content__wrap
          padding: 0
  .produit-banner
    .v-image
      .v-responsive__content
        width: unset !important
</style>
