<script>
export default {
  name: 'DistributionItem',
  computed: {
    showDistribution: {
      get () {
        return this.$store.state.Dialogs.showDistribution
      },
      set (val) {
        this.$store.commit('Dialogs/setShowDistribution', val)
      }
    },
    elemToDistribute: {
      get () {
        return this.$store.state.Dialogs.elemToDistribute
      },
      set (val) {
        this.$store.commit('Dialogs/setElemToDistribute', val)
      }
    }
  },
  data () {
    return {
      loading: false,
      classSelect: [],
      showError: false,
      finish: false,
      numberToGenerate: 0,
      codes: null,
      searchClass: null
    }
  },
  methods: {
    async generateCode () {
      this.loading = true
      this.finish = false
      this.showError = false
      await this.$apollo.mutate({
        mutation: require('@/graphql/mutations/v2/distribuerNuageItems.gql'),
        variables: {
          item: this.elemToDistribute.id,
          distribution: [{
            credit: parseInt(this.numberToGenerate),
            role: 'SALLE_CLASSE_ELEVE'
          }]
        }
      }).then(async ({ data }) => {
        if (!data.distribuerNuageItems[0] || data.distribuerNuageItems[0].total === 0) {
          this.showError = true
          this.loading = false
        } else {
          await this.getNuageItem()
        }
      })
    },
    async getNuageItem () {
      await this.$apollo.query({
        query: require('@/graphql/queries/v2/nuageItem.gql'),
        variables: {
          id: this.elemToDistribute.id
        }
      }).then(({ data }) => {
        if (data.nuageItem && data.nuageItem.nuageItems.length > 0) {
          this.codes = data.nuageItem.nuageItems
          this.finish = true
          this.loading = false
        }
      })
    }
  }
}
</script>

<template lang="pug">
  v-dialog(v-if='elemToDistribute', v-model='showDistribution', max-width='450',content-class='custom-dialog', persistent, scrollable, :fullscreen='$store.state.App.windowSize.width < 700')
    v-card.light
      v-hover(v-slot:default="{ hover }")
        v-btn.dialog-button(@click='showDistribution = !showDistribution', depressed, fab, x-large, :color='$store.state.Preferences.darkMode ? "primary" : "light"')
          font-awesome-icon.f3(:icon="['fas', 'times']")
      v-card-title.dialog-title.secondary.white--text {{$t("action.gen-codes-pour")}}&nbsp; {{elemToDistribute.boutiqueItem.titre}}
      template(v-if='!loading')
        v-card-text.relative
          div(v-if='showError') {{$t("messages.erreur-gen-codes")}}
          v-text-field(:disabled='loading', v-model='numberToGenerate', label='Nombre de codes à généré', type='number')
        v-card-actions
          v-btn(@click='generateCode', color='primary', depressed, x-large, :disabled='loading || !numberToGenerate || numberToGenerate === 0') {{$t("action.gen-codes")}}
      template(v-else-if='loading')
        v-progress-circular(indeterminate, color='primary')
      template(v-else-if='finish')
        v-card-text.relative
          div {{$t("messages.liste-codes")}}
            div(v-for='(code, index) in codes', :key='index')
              span {{code.uid}}
        v-card-actions
          v-btn(@click='showDistribution = !showDistribution', @click.native='elemToDistribute = null', color='primary', depressed, x-large) {{$t("action.quitter")}}
</template>

<style>

</style>
