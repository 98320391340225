<script>
import TextPattern from '@/components/shared/textPattern.vue'
import BoutiqueFavorite from '@/mixins/boutiqueFavorite.js'
export default {
  name: 'ExerciceCard',
  mixins: [BoutiqueFavorite],
  components: {
    TextPattern
  },
  props: {
    listView: { required: false, type: Boolean, default: false },
    produit: { required: true, type: Object },
    maxWidth: { required: false, type: Number, default: 0 },
    classemaison: { required: false, type: Boolean, default: false }
  },
  data () {
    return {
      showDescription: false
    }
  },
  computed: {
    couverture () {
      if (this.produit && this.produit.image && this.produit.image.medium_image) {
        return { couverture: 'https://plus.dokoma.com' + this.produit.image.medium_image, thumbnail: 'https://plus.dokoma.com' + this.produit.image.thumbnail }
      } else if (this.produit && this.produit.images && this.produit.images.length > 0) {
        const couv = this.produit.images.find(e => e.tagsVisibles.find(t => t.id === 'details-graphiques_image-couverture'))
        if (couv && couv.thumbnail) {
          return { couverture: couv.large_image, thumbnail: couv.thumbnail }
        } else {
          return null
        }
      }
      return null
    },
    picto () {
      if (this.produit && this.produit.image_picto && this.produit.image_picto.url_to_image) {
        return this.produit.image_picto.url_to_image.includes('svg') ? this.produit.image_picto.url_to_image : 'https://plus.dokoma.com' + this.produit.image_picto.url_to_image
      } else if (this.produit && this.produit.images && this.produit.images.length > 0) {
        const picto = this.produit.images.find(e => e.tagsVisibles.find(t => t.id === 'details-graphiques_image-pictogramme'))
        if (picto) {
          return picto.thumbnail
        }
      }
      return null
    },
    queryLink () {
      if (this.classemaison) {
        return { document: 'classe-a-la-maison' }
      } else if (this.produit.__typename === 'BoutiqueItem') {
        return { document: this.produit.slug, nuage: true }
      } else {
        return { document: this.produit.slug }
      }
    }
  },
  watch: {
  },
  beforeDestroy () {
  },
  mounted () {
    // var el = document.querySelector('.produit-content')
    // var height = el.scrollHeight
    // el.style.setProperty('--max-height', height + 'px')
  },
  methods: {
    truncate (text, length) {
      if (text.length > length) {
        return text.substring(0, length) + '...'
      } else {
        return text
      }
    }
  }
}
</script>

<template lang='pug'>

router-link.produit-card.no-underline.small-card.secondary--text(:to="{ name: 'exercices' , query: queryLink}", :class='{"list-view" : listView}', :style='{maxWidth: maxWidth > 0 ? maxWidth + "px" : "unset"}')
  template(v-if='classemaison')
    template(v-if='listView')
      v-list-item-avatar.white()
        v-img.relative(src='@/assets/images/logos/logo-classe-maison.svg')
      v-list-item-content
        div.f6 La classe à la maison
        //- v-list-item-subtitle
      //-     .produit-subtitles__wrapper
      //-       template(v-if='produit.matieres')
      //-         template(v-for='(matiere, index) in produit.matieres')
      //-           .produit-item {{matiere.titre}}
      //-       template(v-if='produit.niveaux')
      //-         template(v-for='(niveau, index) in produit.niveaux')
      //-           .produit-item {{niveau.titre}}
      v-list-item-action
        div.flex
          //- v-btn(icon, small, :color='isFav ? "error" : "secondary"', @click.prevent='toggleFavBoutique(produit)')
            font-awesome-icon(:icon="[isFav ? 'fas' : 'far', 'heart']", style='font-size: 18px')
          v-btn(icon,small)
            font-awesome-icon(:icon="['far', 'arrow-right']", style='font-size: 18px')
    template(v-else)
      .produit-description(:class='{"visible-description" : showDescription}')
        text-pattern(:data='"Classe à la maison"', :color='"var(--v-secondary-lighten1)"', :size='40', :opacity='0.5', :quantity='100')
        //- div.f5.relative(v-if='produit.description', v-html='truncate(produit.description, $store.state.App.mobileTemplate ? 150 : 200)')
        div.flex-grow-1
        div
          v-btn(:to="{ name: 'exercices' }", v-ripple, color='primary', rounded, block)
            span Voir le document
            font-awesome-icon.f6.ml2(:icon="['fas', 'arrow-right']")
      .produit-image()
        //- v-img.relative.h-100(v-if='couverture && couverture.couverture', :src='couverture.couverture', :lazy-src='couverture.thumbnail')
        text-pattern(:data='"Classe à la maison"', :color='"var(--v-secondary-base)"', :size='40', :opacity='0.1', :quantity='100')
      .produit-content()
        .actions-header
          v-btn.description-button.mr1(v-if='produit.description', icon, small, color='light', @click.prevent='showDescription = !showDescription')
            font-awesome-icon(:icon="['fas', showDescription ? 'times' : 'info']", style='font-size: 18px')
          //- v-btn.description-button(icon, small, :color='isFav ? "error" : "light"', @click.prevent='toggleFavBoutique(produit)')
            font-awesome-icon(:icon="[isFav ? 'fas' : 'far', 'heart']", style='font-size: 18px')
        v-avatar.mb2.relative(size='45', color='transparent')
          div.color-fallback()
          img.avatar-image.pa1(src='@/assets/images/logos/logo-classe-maison.svg', type='svg')
        .produit-title.relative La classe à la maison
        //- .produit-infos.relative
          .produit-niveau {{produit.niveau}}
          .produit-categorie {{produit.matiere}}
  template(v-else)
    template(v-if='listView')
      v-list-item-avatar.white()
        v-img.relative(v-if='picto', :src='picto')
      v-list-item-content
        div.f6 {{produit.titre}}
        v-list-item-subtitle
          .produit-subtitles__wrapper
            template(v-if='produit.matieres')
              template(v-for='(matiere, index) in produit.matieres')
                .produit-item {{matiere.titre}}
            template(v-if='produit.niveaux')
              template(v-for='(niveau, index) in produit.niveaux')
                .produit-item {{niveau.titre}}
      v-list-item-action
        div.flex
          v-btn(icon, small, :color='isFav ? "error" : "secondary"', @click.prevent='toggleFavBoutique(produit)')
            font-awesome-icon(:icon="[isFav ? 'fas' : 'far', 'heart']", style='font-size: 18px')
          v-btn(icon,small)
            font-awesome-icon(:icon="['far', 'arrow-right']", style='font-size: 18px')
    template(v-else)
      .produit-description(:class='{"visible-description" : showDescription}')
        text-pattern(:data='produit.titre', :color='"var(--v-secondary-lighten1)"', :size='40', :opacity='0.5', :quantity='100')
        div.f5.relative(v-if='produit.description', v-html='truncate(produit.description, $store.state.App.mobileTemplate ? 150 : 200)')
        div.flex-grow-1
        div
          v-btn(:to="{ name: 'exercices' }", v-ripple, color='primary', rounded, block)
            span action.voir-docu
            font-awesome-icon.f6.ml2(:icon="['fas', 'arrow-right']")
      .produit-image()
        v-img.relative.h-100(v-if='couverture && couverture.couverture', :src='couverture.couverture', :lazy-src='couverture.thumbnail')
        text-pattern(v-else-if='produit.titre', :data='produit.titre', :color='"var(--v-secondary-base)"', :size='40', :opacity='0.1', :quantity='100')
      .produit-content()
        .actions-header
          v-btn.description-button.mr1(v-if='produit.description', icon, small, color='light', @click.prevent='showDescription = !showDescription')
            font-awesome-icon(:icon="['fas', showDescription ? 'times' : 'info']", style='font-size: 18px')
          v-btn.description-button(icon, small, :color='isFav ? "error" : "light"', @click.prevent='toggleFavBoutique(produit)')
            font-awesome-icon(:icon="[isFav ? 'fas' : 'far', 'heart']", style='font-size: 18px')
        v-avatar.mb2.relative(v-if='picto', size='45', color='transparent')
          div.color-fallback()
          img.avatar-image(:src='picto', type='svg')
        .produit-title.relative {{produit.titre}}
        v-divider.relative.w-100(color='white')
        .produit-infos.relative.mt1
          .produit-format(v-if='produit.formats && produit.formats.length > 0') {{ produit.formats[0].titre }}
          .produit-niveau {{produit.niveau}}
          .produit-categorie {{produit.matiere}}
</template>
<style lang='sass'>
@import 'src/styles/components/presentation/_productCard'

</style>
