<script>
import DistributionItem from '@/components/dialogs/actions/distributionItem'
import GroupesDistribution from '@/components/presentation/groupesDistribution.vue'
import GetDetailContenu from '@/components/bibliotheque/getDetailContenu.vue'
import ElevePreview from '@/components/presentation/elevePreview.vue'
import LecturesPreview from '@/components/bibliotheque/lecturesPreview.vue'
import ExerciceDetailsGraph from '@/components/exercices/exerciceDetailsGraph.vue'
// import ClasseMaisonFavoris from '@/components/exercices/classeMaisonFavoris.vue'
import RemainingDays from '@/components/shared/remainingDays.vue'

import BoutiqueFavorite from '@/mixins/boutiqueFavorite.js'

export default {
  name: 'PresentationExercice',
  props: {
    nuage: { required: false, type: Object, default: () => null },
    classemaison: { required: false, type: Boolean, default: false }
  },
  mixins: [BoutiqueFavorite],
  components: {
    DistributionItem,
    GroupesDistribution,
    GetDetailContenu,
    ElevePreview,
    LecturesPreview,
    RemainingDays,
    ExerciceDetailsGraph
    // ClasseMaisonFavoris
  },
  data () {
    return {
      infoProduit: null,
      nuageItem: null,
      boutiqueItem: null,
      loading: true,
      panel: 0,
      panels: [0],
      expand: false,
      refetchEleves: false
    }
  },
  apollo: {
    nuageItem: {
      query: require('@/graphql/queries/v2/nuageItem.gql'),
      variables () {
        return {
          id: this.nuage.id,
          page: 1,
          limit: 12
        }
      },
      update (data) {
        console.log({ data })
        return data.nuageItem
      },
      fetchPolicy: 'network-only',
      skip () {
        return !this.nuage || !this.nuage.id
      }
    },
    infoProduit: {
      query: require('@/graphql/queries/plus/graph.gql'),
      variables () {
        return {
          slug: this.$route.query.document
        }
      },
      client: 'plus',
      update (data) {
        this.loading = false
        return data.graph_by_slug
      },
      fetchPolicy: 'network-only',
      watchLoading (isLoading) {
        this.loading = isLoading
      },
      skip () {
        return !!this.$route.query.nuage
      }
    },
    boutiqueItem: {
      query: require('@/graphql/queries/v2/boutiqueItemBy.gql'),
      variables () {
        return {
          attrs: { slug: this.$route.query.document }
        }
      },
      update (data) {
        this.loading = false
        return data.boutiqueItemBy
      },
      fetchPolicy: 'network-only',
      watchLoading (isLoading) {
        this.loading = isLoading
      },
      skip () {
        return !this.$route.query.nuage
      }
    }
  },
  computed: {
    showElevesSelection: {
      set (val) { this.$store.commit('Dialogs/setElevesSelection', val) },
      get () { return this.$store.state.Dialogs.showElevesSelection }
    },
    selectedEleves: {
      set (val) { this.$store.commit('Dialogs/setSelectedEleves', val) },
      get () { return this.$store.state.Dialogs.selectedEleves }
    },
    showDistribution: {
      get () {
        return this.$store.state.Dialogs.showDistribution
      },
      set (val) {
        this.$store.commit('Dialogs/setShowDistribution', val)
      }
    },
    isDistribuable () {
      let temp = false
      let validator = this.nuage && this.nuage.distribution && this.nuage.distribution.find(e => e.role.toLowerCase().includes('eleve')) && (this.nuage.distribution.find(e => e.role.toLowerCase().includes('eleve')).total > 0 || this.nuage.distribution.find(e => e.role.toLowerCase().includes('eleve')).total === -1)
      if (validator) {
        temp = true
      }
      return temp
    },
    showEleveList () {
      return this.isDistribuable || this.nuage?.nuageItemsCreateurs?.total
    },
    mobileTemplate () {
      return this.$store.state.App.mobileTemplate
    },
    profile () {
      return this.$store.state.User.profile
    },
    produit () {
      if (this.infoProduit) {
        return this.infoProduit
      } else if (this.boutiqueItem) {
        return this.boutiqueItem
      } else {
        return null
      }
    },
    contenusIds () {
      return this.produit?.contenus?.map(c => c.id) ?? []
    },
    couverture () {
      if (this.produit && this.produit.image && this.produit.image.medium_image) {
        return { couverture: 'https://plus.dokoma.com' + this.produit.image.medium_image, thumbnail: 'https://plus.dokoma.com' + this.produit.image.thumbnail }
      } else if (this.produit && this.produit.images && this.produit.images.length > 0) {
        const couv = this.produit.images.find(e => e.tagsVisibles.find(t => t.id === 'details-graphiques_image-couverture'))
        if (couv && couv.imageUrl) {
          return { couverture: couv.imageUrl, thumbnail: couv.imageUrl }
        } else {
          return null
        }
      }
      return null
    },
    imagesList () {
      let images = {}
      if (this.produit && this.produit.images && this.produit.images.length) {
        this.produit.images.forEach(img => {
          images.type = 'sku'
          if (img.tagsVisibles && img.tagsVisibles.find(tag => tag.id === 'details-graphiques_image-couverture')) {
            images.coverImage = img
          } else if (img.tagsVisibles && img.tagsVisibles.find(tag => tag.id === 'details-graphiques_image-pictogramme')) {
            images.pictogramme = img
          }
        })
      } else {
        images.type = 'produit'
        if (this.produit.image) {
          images.coverImage = this.produit.image
        }
        if (this.produit.image_picto) {
          images.pictogramme = this.produit.image_picto
        }
      }
      return images
    },
    nbCreditsEleves () {
      const eleveDistri = this.nuageItem?.distribution.find(d => d.role === 'salle_classe_eleve')
      return eleveDistri !== undefined ? eleveDistri?.total : 0
    }
  },
  watch: {
    isDistribuable: {
      immediate: true,
      handler (newVal) {
        if (newVal) {
          this.expand = false
        } else {
          this.expand = true
        }
      }
    },
    '$store.state.App.showSnackbarGlobal' (newVal) {
      if (newVal && this.$store.state.Dialogs.selectedEleves?.length > 0) {
        this.giveToChild()
      }
    },
    '$store.state.Dialogs.selectedEleves': {
      deep: true,
      handler (newVal) {
        this.nuageItem = null
        this.$apollo.queries.nuageItem.refetch()
      }
    }
  },
  methods: {
    updateElevesList () {
      this.refetchEleves = !this.refetchEleves
    },
    async giveToChild () {
      const variab = { item: this.nuageItem.id }
      variab.usagers = this.selectedEleves.map(e => e.id)
      console.log({ variab })
      await this.$apollo.mutate({
        mutation: require('@/graphql/mutations/v2/distribuerNuageItems.gql'),
        variables: variab
      }).then(({ data }) => {
        if (data.distribuerNuageItems) {
          this.updateElevesList()

          // I'm not sure why this exists but I will leave it as it is for the moment
          setTimeout(() => {
            this.$store.dispatch('App/fetchRessourcesExercices')
          }, 1000)
        }
      })
    },
    addToHisto () {
      this.$store.commit('App/addEntryToHisto', this.produit)
    },
    getFontSize (mot) {
      if (mot.length <= 10) {
        return 2 + 'em'
      } else if (mot.length > 10 && mot.length <= 20) {
        return 2 + 'em'
      } else if (mot.length > 20 && mot.length <= 40) {
        return 2 + 'em'
      } else if (mot.length > 30 && mot.length <= 50) {
        return 2 + 'em'
      } else {
        return 1.75 + 'em'
      }
    },
    openEleveSelection () {
      this.showElevesSelection = true
    }
  }
}
</script>

<template lang='pug'>
  .produit-index.split-page(v-if='produit && produit.titre')
    template(v-if='classemaison')
      classe-maison-favoris
    template(v-else)
      v-sheet.page-header(color='secondary')
        .header-image
          v-img.w-100.h-100(v-if='imagesList && imagesList.type === "sku" && imagesList.coverImage', :src='imagesList.coverImage.large_image', :lazy-src='imagesList.coverImage.thumbnail')
          v-img.w-100.h-100(v-else-if='imagesList && imagesList.type === "produit" && imagesList.coverImage', :src='imagesList.coverImage.image_type === "Svg" ? imagesList.coverImage.medium_image : "https://plus.dokoma.com" + imagesList.coverImage.large_image', :lazy-src='imagesList.coverImage.image_type === "Svg" ? imagesList.coverImage.medium_image : "https://plus.dokoma.com" + imagesList.coverImage.large_image')
        .header__inner-content
          div.flex.justify-between.items-center.w-100.mb3
            v-btn.white--text(:small='$store.state.App.mobileTemplate', :to='{ name: "exercices" }', rounded, color='primary')
              font-awesome-icon.mr2.f4(:icon="['fas', 'arrow-left']")
              span {{ $t('exercices.tous-exercices')}}
            v-btn.description-button(icon, :color='isFav ? "error" : "light"', @click.prevent='toggleFavBoutique(produit)')
              font-awesome-icon(:icon="[isFav ? 'fas' : 'far', 'heart']", style='font-size: 22px')
          div.flex-grow-1
          template
            v-avatar.mb1.relative(v-if='imagesList && imagesList.pictogramme', size='45', color='transparent')
              div.color-fallback()
              v-img(v-if='imagesList.type === "sku"', :src='imagesList.pictogramme.thumbnail')
              v-img(v-else-if='imagesList.type === "produit"', :src='imagesList.pictogramme.image_type === "Svg" ? imagesList.pictogramme.url_to_image : "https://plus.dokoma.com" + imagesList.pictogramme.url_to_image')
            .header-title()
              div.secondary-title(v-if='produit.formats && produit.formats.length > 0') {{ produit.formats[0].titre }}
              div.main-title(:style="{ fontSize: getFontSize(produit.titre)}") {{produit.titre}}
              .header__extra-content
                remaining-days(v-if='nuageItem && nuageItem.finAt' :endDate='nuageItem.finAt' context='card')
                v-chip.primary.lighten-5.secondary--text.mt1(small)
                  font-awesome-icon.f6.mr2(:icon="['fad', 'database']")
                  span {{ nbCreditsEleves === -1 ? $t('exercices.credits-eleve-inf') : $tc('exercices.credits-eleve-dispo', nbCreditsEleves, { nb: nbCreditsEleves })}}
              //- v-btn(small, rounded) Ajouter aux favoris

      .page-actions
        v-card(v-if='nuage && isDistribuable' color='primary' v-ripple @click="openEleveSelection")
          v-card-title 1 - {{ $t('action.attri-exercice') }}
        v-card(v-if='nuage', color='primary', :to='{ name: "devoirEdition", query: produit.nuageItem ? { produit: produit.id } : { graph: produit.slug } }', v-ripple)
          v-card-title(v-if='nuage && isDistribuable') 2 - {{ $t('action.creer-devoir') }}
          v-card-title(v-else) {{ $t('action.creer-devoir') }}
        //- v-card.pointer(v-if='nuage', color='primary', @click='showElevesSelection = true', v-ripple)
        //-   v-card-title Attribuer le document
      .page__main-content.mt3.animated.fadeIn
        section.fullsize-section(v-if='produit.descriptionNuage || (produit.public && produit.descriptionPublic) || (!nuage && produit.description)')
          div.relative.mb4
            v-expand-transition
              v-card.transparent(v-show='expand', height='100%', width='100%', flat)
                template(v-if='nuage')
                  div.produit-description(v-if='produit.public && produit.descriptionPublic', v-html='produit.descriptionPublic')
                  div.produit-description(v-else-if='produit.descriptionNuage', v-html='produit.descriptionNuage')
                template(v-else-if='produit.description')
                  .produit-description(v-html='produit.description')
            v-btn(@click='expand = !expand', text, block, small, rounded ,outlined)
              | {{expand ? $t('action.masque-description') : $t('action.affiche-description') }}
              font-awesome-icon.ml2(:icon="['fas', expand ? 'caret-up' : 'caret-down']", style='font-size: 18px')

        section.large-section.mb4
          .section-subtitle__header(v-if='isDistribuable')
            h2.section-subtitle {{ $t('partage.contenu') }}
          //- pre {{produit.contenus}}
          v-sheet.section-card(v-if='produit.contenus', style='padding: 0; overflow: hidden;')
            //- span allo v1 = graphNoeud
            //- pre {{produit.contenus}}
            v-list(v-if='produit.contenus.length')
              v-expansion-panels(v-model='panels', light)
                template(v-for='(contenu, index) in produit.contenus')
                  get-detail-contenu(:contenu='contenu', :nuage='nuage', exercice=true)
            div.empty-content__wrapper.pa4(v-else)
              div {{ $t('messages.docu-non-attri') }}
              v-btn.mt3(rounded, color='primary', @click='showElevesSelection = !showElevesSelection') {{ $t('action.ajout-eleves') }}
          v-sheet.section-card.mt-5(v-if='contenusIds && contenusIds.length' style='padding: 0; overflow: hidden;')
            exercice-details-graph(:devoirsContenus="contenusIds")
          v-btn.mt4(v-else, @click='addToHisto', x-large, color='primary',rounded , dark, :href='"https://glossaire.dokoma.com/dokoma/fr/" + produit.slug', target='_blank', block)
            span.f4.mr3 {{ $t('action.consulter') }}
            font-awesome-icon.f3(:icon="['fad', 'eye']")
          //- pre {{produit.contenus}}
          template(v-if='isDistribuable')
            .section-subtitle__header.mt4
              h2.section-subtitle {{ $t('exercices.exercice-contenu') }}
            v-sheet.section-card.groupes(style='padding: 0; overflow: hidden;', light, :color='$store.state.Preferences.darkMode ? "light" : "white"')
              lectures-preview(:activeId='$route.query.document', exercice=true, :query='produit.nuageItem ? { produit: produit.id } : { graph: produit.slug }')
        section.small-section(v-if='showEleveList')
          .section-subtitle__header
            h2.section-subtitle {{ $t('lecture.attribution') }}
          v-sheet.section-card.groupes(style='padding: 0; overflow: hidden;', light, :color='$store.state.Preferences.darkMode ? "light" : "white"')
            groupes-distribution(v-if="nuageItem && nuageItem.id" :nuageItemId="nuageItem.id")
              template(v-slot:no-student-found)
                div.empty-content__wrapper.pa4
                  div {{ $t('messages.docu-non-attri') }}
                  v-btn.mt3(rounded, color='primary', @click='openEleveSelection') {{ $t('action.ajout-eleves') }}
</template>
<style lang='sass'>
  @import 'src/styles/components/bibliotheque/_produit'
  .section-card.groupes
    .v-expansion-panels
      .v-expansion-panel
        &:not(:last-of-type)
          border-bottom: solid 1px white
      .v-expansion-panel-content
        .v-expansion-panel-content__wrap
          padding: 0
  .produit-banner
    .v-image
      .v-responsive__content
        width: unset !important
</style>
